(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@alt1/base"));
	else if(typeof define === 'function' && define.amd)
		define(["@alt1/base"], factory);
	else if(typeof exports === 'object')
		exports["@alt1/ocr"] = factory(require("@alt1/base"));
	else
		root["OCR"] = factory(root["A1lib"]);
})((typeof self!='undefined'?self:this), (__WEBPACK_EXTERNAL_MODULE__1__) => {
return 